import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-regular-svg-icons'
import { faFacebookF } from '@fortawesome/free-brands-svg-icons'

import Layout from "../components/layout"
import Seo from "../components/seo"

import './index.css'

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <div className="row centered">
      <div className="text-column">
        <h1>
          We build digital products &amp; services
        </h1>
        <h2 className="subtitle">
          Be Empowered to Realize your Ideas.
        </h2>
        <p className="subtitle">
          We are an App Design and Development Studio that enables you to transform your innovative ideas into real working products of high-quality at an affordable budget.
        </p>
        <div className="subtitle">
          <div className="contact-us"><strong>Contact</strong> us:</div>
          <div className="contact-us-item">
            <a href="mailto:hello@crafttechlabs.com">
              <FontAwesomeIcon icon={faEnvelope} className="contact-icon" fixedWidth />
              <span className="contact-us-item-text">hello@crafttechlabs.com</span>
            </a>
          </div>
          <div className="contact-us-item">
            <a href="https://m.me/crafttechlabs">
              <FontAwesomeIcon icon={faFacebookF} className="contact-icon" fixedWidth />
              <span className="contact-us-item-text">fb.me/crafttechlabs</span>
            </a>
          </div>
        </div>
      </div>
      <div className="phone-image">
        <StaticImage
          src="../images/iphone-1.png"
          width={350}
          quality={95}
          formats={["auto", "webp", "avif"]}
          alt="iphone-1"
        />
      </div>
      <div className="phone-image">
        <StaticImage
          src="../images/iphone-2.png"
          width={350}
          quality={95}
          formats={["auto", "webp", "avif"]}
          alt="iphone-1"
        />
      </div>
    </div>
  </Layout>
)

export default IndexPage
